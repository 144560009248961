import React, { useState, lazy, Suspense, useEffect } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { ThemeProvider, styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { AnimatePresence } from 'framer-motion';
import { lightTheme, darkTheme } from './theme';
import { CircularProgress, Box } from '@mui/material';

import Header from './components/Header';
import AnimatedPage from './components/AnimatedPage';
import SkipLink from './components/SkipLink';
import ErrorBoundary from './components/ErrorBoundary';

const Home = lazy(() => import('./components/Home'));
const Announcements = lazy(() => import('./components/Announcements'));
const Login = lazy(() => import('./components/Login'));
const Profile = lazy(() => import('./components/Profile'));
const AdminPanel = lazy(() => import('./components/AdminPanel'));
const Dashboard = lazy(() => import('./components/Dashboard'));
const EmailSubscription = lazy(() => import('./components/EmailSubscription'));

// Styled components for the loading state
const LoadingContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  background: theme.palette.background.default,
  '& .MuiCircularProgress-root': {
    color: theme.palette.primary.main,
    filter: 'drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.1))'
  }
}));

// Main content wrapper with neumorphic styling
const MainContent = styled('main')(({ theme }) => ({
  minHeight: 'calc(100vh - 64px)', // Adjust based on header height
  background: theme.palette.background.default,
  padding: theme.spacing(3),
  transition: 'all 0.3s ease-in-out',
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `radial-gradient(
      circle at center,
      ${theme.palette.background.default} 0%,
      ${theme.palette.mode === 'light' ? '#E5EEF6' : '#141924'} 100%
    )`,
    zIndex: -1
  }
}));

function App() {
  const [darkMode, setDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('darkMode');
    return savedMode ? JSON.parse(savedMode) : false;
  });

  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return !!localStorage.getItem('token');
  });

  const [userRole, setUserRole] = useState(() => {
    return localStorage.getItem('userRole') || 'Viewer';
  });

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const handleLogin = (token, role) => {
    localStorage.setItem('token', token);
    localStorage.setItem('userRole', role);
    setIsAuthenticated(true);
    setUserRole(role);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userRole');
    setIsAuthenticated(false);
    setUserRole('Viewer');
  };

  const location = useLocation();

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <ErrorBoundary>
        <SkipLink />
        <Header 
          darkMode={darkMode} 
          toggleDarkMode={toggleDarkMode} 
          isAuthenticated={isAuthenticated} 
          onLogout={handleLogout}
          userRole={userRole}
        />
        <MainContent id="main-content">
          <Suspense 
            fallback={
              <LoadingContainer>
                <CircularProgress size={60} thickness={4} />
              </LoadingContainer>
            }
          >
            <AnimatePresence mode='wait'>
              <Routes location={location} key={location.pathname}>
                <Route path="/" element={<AnimatedPage><Home /></AnimatedPage>} />
                {userRole === 'Admin' && (
                  <Route 
                    path="/dashboard" 
                    element={
                      isAuthenticated ? 
                      <AnimatedPage><Dashboard userRole={userRole} /></AnimatedPage> : 
                      <Navigate to="/login" replace />
                    } 
                  />
                )}
                <Route 
                  path="/announcements" 
                  element={
                    isAuthenticated ? 
                    <AnimatedPage><Announcements userRole={userRole} /></AnimatedPage> : 
                    <Navigate to="/login" replace />
                  } 
                />
                <Route path="/login" element={<AnimatedPage><Login onLogin={handleLogin} /></AnimatedPage>} />
                <Route 
                  path="/profile" 
                  element={
                    isAuthenticated ? 
                    <AnimatedPage><Profile /></AnimatedPage> : 
                    <Navigate to="/login" replace />
                  } 
                />
                <Route 
                  path="/email-subscription" 
                  element={
                    isAuthenticated ? 
                    <AnimatedPage><EmailSubscription /></AnimatedPage> : 
                    <Navigate to="/login" replace />
                  } 
                />
                <Route 
                  path="/admin" 
                  element={
                    isAuthenticated && userRole === 'Admin' ? 
                    <AnimatedPage><AdminPanel /></AnimatedPage> : 
                    <Navigate to="/" replace />
                  } 
                />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </AnimatePresence>
          </Suspense>
        </MainContent>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;