import React from 'react';
import { 
  AppBar, 
  Toolbar, 
  Button, 
  Box, 
  IconButton,
  styled,
  useTheme
} from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import { ReactComponent as LogoLight } from '../assets/logos/logo-light.svg';
import { ReactComponent as LogoDark } from '../assets/logos/logo-dark.svg';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: theme.palette.mode === 'light' 
    ? 'rgba(235, 244, 250, 0.8)'
    : 'rgba(26, 33, 50, 0.8)',
  backdropFilter: 'blur(10px)',
  boxShadow: 'none',
  borderBottom: `1px solid ${theme.palette.mode === 'light' 
    ? 'rgba(0, 0, 0, 0.1)' 
    : 'rgba(255, 255, 255, 0.1)'}`,
}));

const LogoWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: theme.spacing(4),
  textDecoration: 'none',
  '& svg': {
    height: '40px',
    width: 'auto',
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'scale(1.05)'
    }
  }
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(0, 0.5),
  borderRadius: '12px',
  padding: '8px 16px',
  transition: 'all 0.3s ease',
  color: theme.palette.mode === 'light' ? theme.palette.text.primary : theme.palette.common.white,
  '&:hover': {
    transform: 'translateY(-2px)',
    background: theme.palette.mode === 'light' 
      ? 'rgba(165, 190, 0, 0.1)'
      : 'rgba(184, 208, 0, 0.1)',
  }
}));

const ThemeToggleButton = styled(IconButton)(({ theme }) => ({
  width: 40,
  height: 40,
  borderRadius: '12px',
  marginLeft: theme.spacing(1),
  color: theme.palette.mode === 'light' ? theme.palette.text.primary : theme.palette.common.white,
  background: theme.palette.mode === 'light'
    ? 'rgba(165, 190, 0, 0.1)'
    : 'rgba(184, 208, 0, 0.1)',
  '&:hover': {
    background: theme.palette.mode === 'light'
      ? 'rgba(165, 190, 0, 0.2)'
      : 'rgba(184, 208, 0, 0.2)',
  }
}));

const Header = ({ darkMode, toggleDarkMode, isAuthenticated, onLogout, userRole }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleLogout = () => {
    onLogout();
    navigate('/');
  };

  return (
    <StyledAppBar position="sticky">
      <Toolbar>
        <LogoWrapper component={RouterLink} to="/">
          {theme.palette.mode === 'dark' ? <LogoDark /> : <LogoLight />}
        </LogoWrapper>

        <Box sx={{ flexGrow: 1, display: 'flex', gap: 1 }}>
          <StyledButton
            component={RouterLink}
            to="/"
            startIcon={<HomeIcon />}
          >
            Home
          </StyledButton>

          {isAuthenticated && (
            <>
              {userRole === 'Admin' && (
                <StyledButton
                  component={RouterLink}
                  to="/dashboard"
                  startIcon={<DashboardIcon />}
                >
                  Dashboard
                </StyledButton>
              )}
              
              <StyledButton
                component={RouterLink}
                to="/announcements"
                startIcon={<AnnouncementIcon />}
              >
                Announcements
              </StyledButton>
            </>
          )}
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {isAuthenticated ? (
            <>
              <StyledButton
                component={RouterLink}
                to="/profile"
                startIcon={<AccountCircleIcon />}
              >
                Profile
              </StyledButton>

              <StyledButton
                component={RouterLink}
                to="/email-subscription"
                startIcon={<NotificationsIcon />}
              >
                Subscribe
              </StyledButton>

              {userRole === 'Admin' && (
                <StyledButton
                  component={RouterLink}
                  to="/admin"
                  startIcon={<AdminPanelSettingsIcon />}
                >
                  Admin
                </StyledButton>
              )}

              <StyledButton
                onClick={handleLogout}
                startIcon={<LogoutIcon />}
              >
                Logout
              </StyledButton>
            </>
          ) : (
            <StyledButton
              component={RouterLink}
              to="/login"
              startIcon={<LoginIcon />}
            >
              Login
            </StyledButton>
          )}

          <ThemeToggleButton
            onClick={toggleDarkMode}
            aria-label={darkMode ? "Switch to light mode" : "Switch to dark mode"}
          >
            {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
          </ThemeToggleButton>
        </Box>
      </Toolbar>
    </StyledAppBar>
  );
};

export default Header;